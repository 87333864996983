import React from 'react';
import '../css/Loading.css';

const Loading = () => {
    return (
        <div className='wrap'>
            <img className='loading-indicator' src='/img/spinner_01.gif' alt='loading-indicator'></img>
        </div>
    )
};

export default Loading;