import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/Admin.css';
import axios from 'axios'
import $ from 'jquery';


const authHeader = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
} // 유저 식별용 헤더

const chatbotId = localStorage.getItem('chatbot_id');

function GetList(page_num, status) {
    $("#board_content").empty();

    // send parameter to backend
    axios.get(`https://camel-personal-api.o2o.kr/manager/session/?page=${page_num}&status=${status}&chatbot_id=${chatbotId}`, authHeader)
        .then((response) => {
            console.log('GetList response >> ', response);
            
            let contents = response.data.result.reverse();
            console.log('게시판 컨텐츠 >> ', typeof(contents), contents);

            const itemsPerPage = 10;
            const startIdx = (page_num - 1) * itemsPerPage;
            
            for (let i = 0; i < contents.length; i++) {
                var each_data = contents[i];
                // console.log('each_data : ', each_data);

                let session_id = each_data['session_id'];
                let content = each_data['content'];
                let date = each_data['date'];
                let time = each_data['time'];
                let status = each_data['status'];
            
                var app_id = 'board_content';
                let displayNumber = startIdx + (contents.length - i);
                
                // status check
                let rowColor;
                if (status === '수정 필요') {
                    rowColor = 'var(--red, #DB524C)';
                } else if (status === '검토 완료') {
                    rowColor = 'var(--green, #25bf72)';
                } else {
                    rowColor = 'var(--black, #000)';
                }

                document.getElementById(app_id).innerHTML += `
                    <tr key=${session_id} class='each_tr_frame' onClick="GetDetail('${session_id}', '${displayNumber}', '${date}', '${time}')">
                        <td class='col_width_basic' style='color:var(--black, #000);'>${displayNumber}</td>
                        <td class='col_width_content' style='color:var(--black, #000);'>${content}</td>
                        <td class='col_width_basic' style='color:var(--black, #000);'>${date}</td>
                        <td class='col_width_basic' style='color:var(--black, #000);'>${time}</td>
                        <td class='col_width_basic' style='color:${rowColor};'>${status}</td>
                    </tr>
                `;
            }
        })
        .catch(() => {
            console.log('GetList >> false')
        })
    }

function PageNumber({ pageNum, onGetList, isActive }) {
    return (
        <p
            className={`each_page_num ${isActive ? 'selected_page' : ''}`}
            onClick={() => onGetList(pageNum)}
        >
            {pageNum}
        </p>
    );
}

function PageNationInitial(){
    axios.get(`https://camel-personal-api.o2o.kr/manager/session/?page=1`, authHeader) // &chatbot_id=${chatbotId}
    .then((response)=>{
        console.log('pagenation initail response >> ', response);
        let page_cnt = response.data;
        page_cnt = page_cnt.total_page_cnt;
        console.log('페이지 네이션(초기) >> ', typeof page_cnt, page_cnt);
        // return page_cnt;
    
        // 페이지 네이션
        var page_id = 'page_number';
    
        for (let i = 0; i< page_cnt; i++){
            return <PageNumber pageNum={i+1} onGetList={GetList} />
        }
    })
    // 예외처리
    .catch(()=>{
        console.log('false');
    })

}

function PageContainer({ tabType }) {
    // 수정 버전
    const [page, setPage] = useState(1); // 현재 페이지 번호
    const [pageCnt, setPageCnt] = useState(0); // 전체 페이지 수

    const btnRange = 10; // 보여질 페이지 버튼의 개수
    const currentSet = Math.ceil(page / btnRange); // 현재 버튼이 몇번째 세트인지 나타내는 수
    const startPage = (currentSet - 1) * btnRange + 1; // 현재 보여질 버튼의 첫번째 수
    const endPage = startPage + btnRange - 1; // 현재 보여질 끝 버튼의 수
    const totalSet = Math.ceil(pageCnt / btnRange); // 전체 버튼 세트 수

    // 기존 페이지네이션 내용 삭제
    // $("#page_number").empty();

    useEffect(() => {
        axios.get(`https://camel-personal-api.o2o.kr/manager/session/?page=1&status=${tabType}`, authHeader)
            .then((response) => {
                console.log('page num resp. >>', response);
                let page_cnt = response.data.total_page_cnt;
                setPageCnt(page_cnt);
                console.log(tabType, '의 페이지수 : ', page_cnt);
            })
            .catch(() => {
                console.log('false');
            });
    }, [tabType]);


    return (
        <div id='page_number'>
            {currentSet > 1 && (
                <button
                    className='page_prev'
                    onClick={() => {
                    setPage(startPage - 1);
                    GetList(page - 1, tabType);
                    }}
                    // $active={page > 0 ? true :false}
                >
                    이전
                </button>
            )}
    
            {Array.from({ length: btnRange }, (_, i) => {
                const pageNumber = startPage + i;
                // 페이지 번호가 전체 페이지 수를 초과하지 않을 때만 렌더링
                return pageNumber <= pageCnt && (
                    <PageNumber
                        key={i}
                        pageNum={pageNumber}
                        onGetList={() => {
                            setPage(pageNumber);
                            GetList(pageNumber, tabType);
                        }}
                        isActive={pageNumber === page} // 현재 페이지 여부 확인
                    />
                );
            })}
            
            {totalSet > currentSet && (
                <button
                    className='page_next'
                    onClick={() => {
                    setPage(endPage + 1);
                    GetList(page + 1, tabType);
                    }}
                    // $active={page < pageCnt ? true :false}
                >
                    다음
                </button>
            )}
        </div>
    );
}

function SelectTapHeader(EcahTapName){
    var target_num = 'tap_header_'+EcahTapName;
    document.getElementById('tap_header_all').style.background = 'none';
    document.getElementById('tap_header_check').style.background = 'none';
    document.getElementById('tap_header_edit').style.background = 'none';
    document.getElementById('tap_header_done').style.background = 'none';

    document.getElementById(target_num).style.background = 'rgb(227 210 191)';
    document.getElementById(target_num).style.borderRadius = '0 0 10px 10px';

    console.log('tap color change');
}

const Admin = () => {
    // 탭선택에 따라 상태값을 페이지 선택 항목에 전달
    let { chatName } = useParams();
    const [selectedTab, setSelectedTab] = useState('all');  // 기본값은 'all'
    const navigate = useNavigate();
    
    // 로그인 정보 유효성 검사
    useEffect(() => {
        const checkBotNameAndId = async () => {
            const response = await axios.get('https://camel-personal-api.o2o.kr/user/chatbot-name/', authHeader);
            const chatbotName = response.data.chatbot_name;
            const chatbotId = response.data.chatbot_id;
            
            console.log('chatName >>', chatName, 'chatbot_name >>', chatbotName);
            console.log('chatbotId >>', chatbotId);

            if (chatName !== chatbotName) {
                console.log('chatName !== chatbotName');
                navigate('/not-allowed');
                return;
            }

            await axios.post(
                'https://camel-personal-api.o2o.kr/user/admin/login/', 
                { chatbot_id: chatbotId }, 
                { headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` } }
            )
            .then((response) => {
                console.log('admin login response >>', response)
                if (response.status !== 200) {
                    console.log('admin login status is not 200 OK.')
                    navigate('/not-allowed');
                }
            })
            .catch((error) => {
                console.error('Validation failed >>', error);
                navigate('/not-allowed');
            });
        }

        checkBotNameAndId();
    }, [chatName, navigate]);

    const handleTabClick = (tabType) => {
        setSelectedTab(tabType);
    };

    useEffect(() => {
        let default_page_num = 1
        let default_status = 'all'
    
        GetList(default_page_num, default_status);  // await keyword added
        PageNationInitial();  
    }, []);

    return (
        <div className='body_admin'>        
        <div className='wrap_admin'>
            {/* 헤더 */}
            <div className='wrap_header'>
                <div>
                    {/* 탭 헤더 */}
                    <div className='tap_header' id='tap_header_all' style={{background: 'var(--view-03, #2e3990)'}} onClick={()=>{SelectTapHeader('all'); handleTabClick('all'); GetList(1, 'all');}}>전체</div>
                    <div className='tap_header' id='tap_header_check' style={{background: 'var(--view-03, #2e3990)'}} onClick={()=>{SelectTapHeader('check'); handleTabClick('check'); GetList(1,'미확인');}}>미확인</div>
                    <div className='tap_header' id='tap_header_edit' style={{background: 'var(--view-03, #2e3990)'}} onClick={()=>{SelectTapHeader('edit'); handleTabClick('edit'); GetList(1,'수정 필요');}}>수정 필요</div>
                    <div className='tap_header' id='tap_header_done' style={{background: 'var(--view-03, #2e3990)'}} onClick={()=>{SelectTapHeader('done'); handleTabClick('done'); GetList(1,'검토 완료');}}>검토 완료</div>
                </div>
            </div>

            {/* 게시판 */}
            <div className='wrap_board' id='wrap_board'>
                <table style={{width: '95%'}} className='table_frame' id='table_frame'>
                    <thead className='thead_basic'>
                    <tr>
                        <th className='col_width_basic'>글번호</th>
                        <th className='col_width_content'>내용</th>
                        <th className='col_width_basic'>날짜</th>
                        <th className='col_width_basic'>시간</th>
                        <th className='col_width_basic'>상태</th>
                    </tr>
                    </thead>
                    <tbody id='board_content'></tbody>
                </table>

                {/* 게시판 상세내용 */}
                <div id='detail_content' className='detail_content' style={{display : "none"}}></div>
            </div>
                {/* 페이지선택 */}
            <div className='page_number' id='page_number'>
                {/* selectedTab 값을 PageContainer에 전달합니다. */}
                <PageContainer tabType={selectedTab} />
            </div>
        </div>
        </div>
    );
}


export default Admin;