import React from 'react';

const Login = () => {
    const REST_API_KEY = process.env.REACT_APP_KAKAO_REST_API_KEY;
    const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    return (
        <>
            <a href={KAKAO_AUTH_URL}>
                <img className='login-btn' src="img/kakao_login_btn.png" alt="login-btn"></img>
            </a>
        </>
    )
}

export default Login;