// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .wrap{
    margin: 0 auto;
    max-width: 480px;
    min-height: 100vh;
    background-color: rgb(255,200,200);
    overflow: hidden;
} */

.loading-indicator {
    display: block;
    margin: auto;
    padding-top: 35vh;
}`, "",{"version":3,"sources":["webpack://./src/css/Loading.css"],"names":[],"mappings":"AAAA;;;;;;GAMG;;AAEH;IACI,cAAc;IACd,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":["/* .wrap{\n    margin: 0 auto;\n    max-width: 480px;\n    min-height: 100vh;\n    background-color: rgb(255,200,200);\n    overflow: hidden;\n} */\n\n.loading-indicator {\n    display: block;\n    margin: auto;\n    padding-top: 35vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
