import React from 'react';
import Login from '../Components/Login';
import '../css/Intro.css';

const Intro = () => {
	return (
		// <div className='wrap' style={{backgroundColor:"#ffc8c8", minHeight:"100vh"}}>
		<div className='wrap'>
            {/* <div className='grid-container'> */}
            <div className='intro-items'>
                <p className='intro-title'>Camel 개인형 서비스</p>
                <Login></Login>
            </div>
		</div>
	);
};

export default Intro;