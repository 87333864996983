import React from "react";
// import { useNavigate  } from "react-router-dom";
import "../css/NotFound.css";

const NotAllowed = () => {
    const checkUserHasCertification = () => {
        // 홈화면까지 정상진입했는지 확인할 변수 필요
        const certification = localStorage.getItem('access_token');
        let returnUrl = 'https://camel-personal.o2o.kr/home'
        if (certification === null) {
            returnUrl = 'https://camel-personal.o2o.kr'
        }
        return returnUrl
    }
    return (
        <div className="wrap">
            <div className="notification-section">
                <p className="info-msg">접근할 수 있는 권한이 없습니다.</p>
                <button className="return-btn" onClick={() => window.location.href = checkUserHasCertification()}>홈으로→</button>
            </div>
        </div>
    )
}

export default NotAllowed;