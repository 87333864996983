import React, { useState } from 'react';

function ImageSlider() {
    const images = [
        '/img/guide_11.svg',
        '/img/guide_22.svg',
        '/img/guide_33.svg'
    ];

    const [current, setCurrent] = useState(0);
    const [dragStartX, setDragStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);

    const handleDragStart = (e) => {
        setDragStartX(e.clientX);
        setIsDragging(true);
    };

    const handleDragMove = (e) => {
        if (isDragging) {
            const dragEndX = e.clientX;
            const dragDistance = dragStartX - dragEndX;

            if (dragDistance > 50) { // 오른쪽으로 드래그
                nextSlide();
                setIsDragging(false);
            } else if (dragDistance < -50) { // 왼쪽으로 드래그
                prevSlide();
                setIsDragging(false);
            }
        }
    };

    const handleDragEnd = () => {
        setIsDragging(false);
    };

    const nextSlide = () => {
        setCurrent((prevCurrent) => (prevCurrent + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrent((prevCurrent) => (prevCurrent - 1 + images.length) % images.length);
    };

    const handleSlideClick = (e) => {
        // e.clientX는 클릭된 X 좌표
        // e.target.offsetWidth는 요소의 전체 너비
        // 조건에 따라 prevSlide 또는 nextSlide를 호출
        if (e.clientX < e.target.offsetWidth / 2) {
            prevSlide();
        } else {
            nextSlide();
        }
    };

    return (
        <div 
            onMouseDown={handleDragStart} 
            onMouseMove={handleDragMove} 
            onMouseUp={handleDragEnd} 
            onMouseLeave={handleDragEnd}
            style={{ userSelect: 'none', maxWidth: '480px', margin: 'auto', overflow: 'hidden' }}
        >
            <div 
                style={{ 
                    display: 'flex', 
                    transition: 'transform 0.5s ease', 
                    transform: `translateX(-${current * 100}%)` 
                }}
                onClick={handleSlideClick}
            >
                {images.map((img, index) => (
                    <div key={index} style={{ minWidth: '100%', flexShrink: 0 }}>
                        <img src={img} alt={`Slide ${index}`} style={{ width: '80%', height: 'auto', marginTop: '100px'}} />
                    </div>
                ))}
            </div>
            
            {/* 슬라이드 이미지 네비게이터 */}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {images.map((_, index) => (
                    <span 
                        key={index} 
                        onClick={() => setCurrent(index)}
                        style={{
                            cursor: 'pointer',
                            height: '15px',
                            width: '15px',
                            margin: '5px',
                            backgroundColor: current === index ? '#ebd5ba' : 'grey',
                            display: 'inline-block',
                            borderRadius: '50%',
                        }}
                    ></span>
                ))}
            </div>
        </div>
    );
}

export default ImageSlider;