// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-items {
    display: flex;
    flex-direction: column;
    margin-top: 35vh;
}

.intro-title {
    text-align: center;
    font-size: 43px;
    font-weight: lighter;
    color: rgb(230,193,149);
}`, "",{"version":3,"sources":["webpack://./src/css/Intro.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,oBAAoB;IACpB,uBAAuB;AAC3B","sourcesContent":[".intro-items {\n    display: flex;\n    flex-direction: column;\n    margin-top: 35vh;\n}\n\n.intro-title {\n    text-align: center;\n    font-size: 43px;\n    font-weight: lighter;\n    color: rgb(230,193,149);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
