import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Pages/Loading';

const RedirectHandler = () => {
    const AUTHORIZE_CODE = new URL(window.location.href).searchParams.get("code");
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(
            'https://camel-personal-api.o2o.kr/user/kakao/login/', {
                code: AUTHORIZE_CODE
            }
        )
        .then((response) => {
            console.log('user login response >>', response)
            if (response.status === 200) {
                console.log('login message :', response.data.message);
                // 토큰을 받아서 localStorage같은 곳에 저장하는 코드를 여기에 쓴다.
                localStorage.setItem('name', response.data.user.nickname); // 사용자 이름 저장
                localStorage.setItem('chatbot_name', response.data.user.chatbot_name); // 챗봇 이름 저장
                localStorage.setItem('pdf_file_name', response.data.user.pdf_file_name); // pdf 파일명 저장
                localStorage.setItem('access_token', response.data.token.access); // access 저장
                localStorage.setItem('refresh_token', response.data.token.refresh); // refresh 저장
                navigate('/guide'); // 로그인 후 사용자가 볼 페이지 네비게이션
            } else {
                navigate('/');
            }
        })
        .catch((error) => {
            console.error('Login failed :', error);
            navigate('/');
        });
    }, []);

    return (
        <>
            <Loading />
        </>
    );
}

export default RedirectHandler;