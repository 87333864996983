// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrap-chat {
    text-align: center;
    margin: 0 auto;
    max-width: 480px;
    /* min-height: 100vh; */
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/css/Chatbot.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".wrap-chat {\n    text-align: center;\n    margin: 0 auto;\n    max-width: 480px;\n    /* min-height: 100vh; */\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
