// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-section {
    margin-top: 35vh;
}

.info-msg {
    font-size: 25px;
    color: rgb(230,193,149);
    padding-bottom: 10px;
}

.info-url {
    font-size: 20px;
    color: red;
}

.return-btn {
    margin-top: 20px;
    width: 30%;
    height: 40px;
    font-size: 20px;
    border-radius: 10px;
    background-color: rgb(230,193,149);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/css/NotFound.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,kCAAkC;IAClC,eAAe;AACnB","sourcesContent":[".notification-section {\n    margin-top: 35vh;\n}\n\n.info-msg {\n    font-size: 25px;\n    color: rgb(230,193,149);\n    padding-bottom: 10px;\n}\n\n.info-url {\n    font-size: 20px;\n    color: red;\n}\n\n.return-btn {\n    margin-top: 20px;\n    width: 30%;\n    height: 40px;\n    font-size: 20px;\n    border-radius: 10px;\n    background-color: rgb(230,193,149);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
