import React from 'react';
import '../css/Guide.css';
import SwiperImage from '../Components/ImageSlider';


const Guide = () => {
    return (
        <div className='wrap'>
            {/* 이미지 슬라이더 구현 */}
            <SwiperImage />
            <div className='go-to-home'>
                <button onClick={() => window.open('https://camel-personal.o2o.kr/home', '_self')}>홈 화면으로 가기</button>
            </div>
        </div>
    );
}

export default Guide;