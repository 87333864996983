import './App.css';
import Intro from './Pages/Intro';
import Home from './Pages/Home';
import Chatbot from './Pages/Chatbot';
import Loading from './Pages/Loading';
import RedirectHandler from './Components/RedirectHandler';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Guide from './Pages/Guide';
import Admin from './Pages/Admin';
import NotFound from './Pages/NotFound';
import NotAllowed from './Pages/NotAllowed';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Intro />} />
                    <Route exact path="/kakao/callback" element={<RedirectHandler />} />
                    <Route path="/guide" element={<Guide />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/chatbot/:userName" element={<Chatbot />} />
                    <Route path="/loading" element={<Loading />} />
                    <Route path="/admin/:chatName" element={<Admin />}/>
                    <Route path="/not-allowed" element={<NotAllowed />} />
                    <Route path="*" Component={ NotFound } />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;