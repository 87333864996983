import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../css/Chatbot.css';


const url = window.location.href;
const chatbotNameEncoded = url.split('/').at(-1);
const chatbotName = decodeURIComponent(chatbotNameEncoded);

const authHeader = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
};

let session_id;

const Chatbot = () => {
    // const userName = window.localStorage.getItem('name')
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState(`안녕하세요, ${chatbotName} 챗봇입니다. 무엇을 도와드릴까요?`);
    const [isChatbotLoading, setIsChatbotLoading] = useState(false); // 답변 대기중 인디케이터 출력
    
    // suggestion chip에 들어갈 faq 관리
    const [rawFaqs, setRawFaqs] = useState([]);
    const [faqs, setFaqs] = useState([]);
    
    const navigate = useNavigate();

    // Admin page data handlings
    const [answerNum, setAnswerNum] = useState(0);

    // 챗봇 마운트 시 최초 시간
    const [initialTime, setInitialTime] = useState('');

    const chatbotId = localStorage.getItem('chatbot_id');
    // 세션 아이디 한 번만 실행
    useEffect(() => {
        const getSessionId = async () => {
            if (session_id) {
                return;
            }

            try {
                const response = await axios.post('https://camel-personal-api.o2o.kr/manager/session/', {
                    chatbot_id: chatbotId
                }, authHeader);
                session_id = response.data['session_id'];
                console.log('get session id response >>', response);
                console.log('session_id >>', session_id);
            } catch (error) {
                console.log('no session_id. false!!');
            }
        };

        getSessionId();
        setInitialTime(getCurrentTime());
    }, []);

    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    
    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();
    
        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);
    
        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행

    // 메세지
    const sendMessage = async (messageToSend = text) => {
        // 입력 요소의 포커스 해제
        document.activeElement.blur();

        
        // 입력된 텍스트 또는 함수에 전달된 메시지를 사용
        const messageContent = messageToSend.trim();
        if (!messageContent) return;
        
        const chatCurrentTime = getCurrentTime();
        setMessages(prevMessages => [
            ...prevMessages, 
            { text: messageContent, sender: 'user', time: chatCurrentTime }
        ]);
        setText('');

        // 챗봇 응답 로직을 여기에 추가
        console.log('user_query >>', messageContent);
        setIsChatbotLoading(true); // 챗봇 응답 전 로딩 시작
        await axios.get('https://camel-personal-api.o2o.kr/chat/', {
            params: { 'query': messageContent, 'chatbot_id': chatbotId },
            headers: { 'Content-Type': 'application/json' }
        })
        .then(response => {
            console.log('chat response >>', response);
            const answer = response.data["output"]
            const chunk = response.data["chunk"]
            console.log('answer >>', answer);
            console.log('chunk >>', chunk);

            // 챗봇 답변 메시지 추가
            setMessages(prevMessages => [...prevMessages, { text: answer, sender: 'bot', time: chatCurrentTime }]);

            // updateChipsWrapperContent(); // suggestion chip 변경

            // Admin page 내용 전송
            console.log('user question >>', messageContent); // user 질문
            console.log('answer api data >>', answer); // api 답변
            postAnswer(session_id, answerNum, messageContent, answer);

            // 답변 index 업데이트
            setAnswerNum(answerNum + 1);
            console.log('answer_num +1 >> ', answerNum);
            
            setIsChatbotLoading(false); // 챗봇 로딩 종료
        })
        .catch(error => {
            console.error('Error >>', error);
            setIsChatbotLoading(false); // 챗봇 로딩 종료
        });
    };

    // Admin 서버에 데이터 전송
    const postAnswer = async (sessionId, answerNumber, question, answer) => {
        try {
            const answerInfo = {
                'sessionId': sessionId,
                'answerNum': answerNumber,
                'question': question,
                'answer': answer,
            }

            const response = await axios.post('https://camel-personal-api.o2o.kr/manager/answer/', answerInfo, authHeader)
            
            console.log('check point 01');
            console.log('answerNum_to_send >>', typeof answerNumber, answerNumber);
            console.log('sessionId >>', typeof sessionId, sessionId);
    
            const data = await response;
            // const data = await response.json();
            console.log('db입력후 response >>', data);
    
        } catch (error) {
            console.error('Error >>', error);
        }
    };

    // 라운드 칩을 유저 데이터에서 받아와서 마운트
    const fetchFAQs = async () => {
        try {
            const response = await axios.get(`https://camel-personal-api.o2o.kr/user/faq/?chatbot_name=${chatbotName}`, authHeader);
            // API로부터 받은 faq가 null이 아니면 해당 값을 사용, null이면 빈 배열 사용
            const faqsData = response.data.faq !== null ? response.data.faq : [];
            console.log('user faq list :', faqsData) // faq debug
            // faq가 3개 이상일 경우 무조건 처음에는 3개만 보여주도록 제한함
            if (faqsData) {
                setRawFaqs(faqsData);
                let randomFaqs = faqsData.sort(() => Math.random() - 0.5).slice(0,4);
                setFaqs(randomFaqs);
            } else {
                setFaqs([]);
            }
        } catch (error) {
            console.error('FAQ 데이터 로딩 에러:', error); 
            setFaqs([]); // 실패 시, faqs는 빈 배열로 유지
        }
    };

    useEffect(() => {
        fetchFAQs();
    }, []);

    const updateChipsWrapperContent = (rawFaqs) => {
        if (rawFaqs) {
            let randomFaqs = rawFaqs.sort(() => Math.random() - 0.5).slice(0,4);
            setFaqs(randomFaqs);
        } else {
            setFaqs([]);
        }
    }

    // 라운드 칩 클릭 시 메시지 전송
    const handleChipClick = (message) => {
        updateChipsWrapperContent(rawFaqs);
        sendMessage(message);
    };

    // 현재 시간을 포맷팅하여 반환하는 함수
    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();
        
        // 시간을 12시간 형식으로 변환
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const period = hours >= 12 ? '오후' : '오전';
        
        // 0을 추가하여 두 자리 숫자로 만듦
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${period} ${formattedHours}:${formattedMinutes}`;
    };

    

    {/* 챗봇 프로필 정보 */}
    const chatbotProfile = {
        botname: `${chatbotName} 챗봇`,
        profileImage: '/img/chatbot.png'
    };

    return (
    <>
        <div className={`chat-container`}>
        {/* 채팅 화면 */}
            {/* <div className='wrap-chat' style={{
                margin: '0 auto',
                height: '100vh',
                backgroundColor: 'rgb(255,200,200)',
                overflow: 'auto'
            }}> */}
            <div className='wrap-chat'>
            <div className='container'>
                {/* 화면 전체 로딩 인디케이터(투명) 시작 */}
                {isChatbotLoading && (
                    <div className="loading-overlay" />
                )}
            <div className='msger'>

            {/* 헤더 */}
            <header className='msger-header'>
                <div className='msger-header-title'>
                    <div className='header-logo-frame'>
                        <p>{chatbotName} 챗봇</p>
                    </div>
                </div>
                {/* <div className="rounded-chip-admin" onClick={
                    () => {
                        if (!isChatbotLoading) {
                            handleAdminClick();
                        }
                    }}>Admin</div> */}
            </header>

            {/* 채팅창 */}
            <main className='msger-chat msger-chat-frame'>
                <section className='msger-chat-prompt'>
                    <div className='scroll-prompt'>
                        <div className='chat-block'>
                            <img src={chatbotProfile.profileImage} alt={`${chatbotProfile.name} 프로필`} className='profile-image' />
                            <div className='profile-name'>{chatbotProfile.botname}</div>
                            <div className='greeting'>
                                {displayedText}
                                <div className="msg-time">{initialTime}</div>
                            </div>
                        </div>
                    
                        {/* 메시지 및 로딩 인디케이터 표시 */}
                        {messages.map((message, index) => (
                            <>
                                {message.sender === 'bot' && (
                                    <div className='chat-block'>
                                        <img src={chatbotProfile.profileImage} alt={`${chatbotProfile.name} 프로필`} className='profile-image' width="45px" />
                                        <div className='profile-name'>{chatbotProfile.botname}</div>
                                        <div className={'msg-bubble msg-left'} key={index}>
                                            <div className='user-say-text'>{message.text}</div>
                                            <div className="msg-time">{message.time}</div>
                                        </div>
                                    </div>
                                )}
                                {message.sender === 'user' && (
                                    <div className='chat-block'>
                                        <div className={'msg-bubble msg-right'} key={index}>
                                            <div className='user-say-text'>{message.text}</div>
                                            <div className="msg-time">{message.time}</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                        <div ref={messagesEndRef} />
                            {/* 화면 전체 로딩 인디케이터(투명) 끝 */}
                            {/* {isChatbotLoading && <div className="loading-indicator"></div>} */}

                            {/* Typing indicator를 나타내는 로딩 인디케이터 */}
                            {isChatbotLoading && (
                                <div className='chat-block'>
                                    <img src={chatbotProfile.profileImage} alt={`${chatbotProfile.name} 프로필`} className='profile-image' width="45px" />
                                    <div className='profile-name'>{chatbotProfile.botname}</div>
                                    <div className="msg-bubble msg-left"> 
                                        <div className="typing-indicator">
                                            <img src="/img/typing_indicator.gif" alt="loading" id="typing-indicator"></img>
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div>
                </section>
                
                <form className='msger-form' onSubmit={(e) => {e.preventDefault(); sendMessage();}}>
                <div className='chips-wrapper' id='chips-wrapper'>
                    {faqs.map((faq, index) => (
                        <div key={index} className="rounded-chip" onClick={() => handleChipClick(faq)}>
                            {faq}
                        </div>
                    ))}
                </div>
                <div className='msger-inputarea'>
                <img style={{ float: 'left', paddingTop: '10px', marginRight:'10px',marginLeft:'12px', cursor:'pointer' }}
                src="/img/home.svg" alt="home"
                onClick={() => {
                    if (!isChatbotLoading) {
                        navigate('/home');
                    }
                    }}></img>
                    <input
                    className="msger-input"
                    id="msger-input"
                    type="text"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder='무엇이든 물어보세요'
                    />
                    <button className="send-btn" type="submit" onClick={(e) => {
                        if (isChatbotLoading) {
                            e.preventDefault();
                        }
                    }}>
                        <img style={{ width: '35px', paddingTop: '0px' }} src="/img/send.svg" alt="send"></img>
                    </button>
                </div>
                </form>
            </main>

        </div>
        </div>
        </div>
        </div>
    </>
    );
    };

export default Chatbot;