import React from "react";
// import { useNavigate  } from "react-router-dom";
import "../css/NotFound.css";

const NotFound = () => {
    const url = window.location.href;

    // function HomeButton() {
    //     const navigate = useNavigate();
    
    //     const handleOnClick = async () => {
    //     try {
    //         const url = await checkUserHasCertification();
    //         navigate(url);
    //     } catch (error) {
    //         console.error('URL을 가져오는 데 실패했습니다:', error);
    //     }
    //     };
    
    //     return (
    //         <button onClick={handleOnClick}>홈으로→</button>
    //     );
    // }
    
    // async function checkUserHasCertification() {
    //     // 서버에 인증 상태 확인 요청
    //     return fetch('/api/verify-certification')
    //     .then(response => {
    //         if (!response.ok) {
    //         throw new Error('인증 검증 실패');
    //         }
    //         return response.json();
    //     })
    //     .then(data => {
    //         return data.isCertified ? '/home' : '/unauthorized';
    //     });
    // }

    const checkUserHasCertification = () => {
        // 홈화면까지 정상진입했는지 확인할 변수 필요
        const certification = localStorage.getItem('access_token');
        let returnUrl = 'https://camel-personal.o2o.kr/home'
        if (certification === null) {
            returnUrl = 'https://camel-personal.o2o.kr'
        }
        return returnUrl
    }
    return (
        <div className="wrap">
            <div className="notification-section">
                <p className="info-msg">요청하신 페이지를 찾을 수 없습니다.</p>
                <p className="info-msg">입력하신 주소가 올바른지<br />다시 한 번 확인해주세요.</p>
                <p className="info-url">{url}</p>
                <button className="return-btn" onClick={() => window.location.href = checkUserHasCertification()}>홈으로→</button>
            </div>
        </div>
    )
}

export default NotFound;