import React, { useEffect, useState } from 'react';
import '../css/Home.css';
import FileUploadComponent from '../Components/PdfUploader';
import axios from 'axios';

const userName = window.localStorage.getItem('name');

const authHeader = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
    }
} // 유저 식별용 헤더

const SuggestQuestion = () => {
    const [inputs, setInputs] = useState(['']);

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await axios.get('https://camel-personal-api.o2o.kr/user/faq/', authHeader);
                // console.log('faq response >>', response);
                setInputs(response.data.faq.length > 0 ? response.data.faq : ['']);
            } catch (error) {
                console.error('FAQ 데이터 로딩 에러 >>', error);
            }
        };

        fetchFAQ();
    }, []);

    const handleChange = (index, event) => {
        const newInputs = [...inputs];
        newInputs[index] = event.target.value;
        setInputs(newInputs);
    };

    const addInput = () => {
        setInputs([...inputs, '']);
    };

    const removeInput = async (index) => {
        const faqData = inputs[index];
        const encodedFaqData = encodeURIComponent(faqData);
        console.log('delete faq data >>', encodedFaqData, typeof(encodedFaqData));

        if (encodedFaqData.length > 0) {
            const response = await axios.delete(`https://camel-personal-api.o2o.kr/user/faq/${encodedFaqData}/`, authHeader);
    
            if (response.status === 200) {
                // 서버에서 데이터가 성공적으로 삭제되면 클라이언트 상의 데이터도 삭제
                const newInputs = inputs.filter((_, i) => i !== index);
                setInputs(newInputs);
            } else {
                console.error('Failed to delete the input on the server >>', await response);
            }
        } else {
            console.log('faq가 빈 문자열이므로 삭제를 요청하지 않음')
        }
    };

    const handleSubmit = async () => {
        // console.log('faq inputs >>', inputs)
        try {
            const response = await axios.put('https://camel-personal-api.o2o.kr/user/faq/', {
                faq: inputs,
            }, authHeader);
            // console.log('FAQ response >>', response.data);
            alert('자주 찾는 질문이 성공적으로 제출되었습니다.');
        } catch (error) {
            console.error('질문 제출 에러 >>', error);
            alert('자주 찾는 질문 제출 중 오류가 발생했습니다.');
        }
    };

    return (
        <div className='suggest-section'>
            {inputs.map((input, index) => (
                <div key={index} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
                    <input
                        key={index}
                        value={input}
                        onChange={(e) => handleChange(index, e)}
                        placeholder='자주 찾는 질문을 입력하세요'
                    />
                    <button onClick={() => removeInput(index)} style={{ 
                        marginLeft: '10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '10px',
                        cursor: 'pointer'
                    }}>
                            삭제
                    </button>
                </div>
            ))}
            <div className='suggest-btn'>
                <button 
                    onClick={addInput}
                    style={{backgroundColor:'skyblue', fontSize: '25px', borderRadius: '100px', width: '50px', height: '50px', cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    +
                </button>
                {inputs.length===0 && (<button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#3ebb3d', color:'white', cursor: 'pointer'}}
                >
                    등록
                </button>)}
                {inputs.length > 0 && (<button 
                    onClick={handleSubmit}
                    style={{backgroundColor:'#3ebb3d', color:'white', cursor: 'pointer'}}
                >
                    업데이트
                </button>)}
            </div>
        </div>
    );
}

const Home = () => {
    const [chatName, setChatName] = useState(''); // 챗봇이름
    const [chatNameId, setChatId] = useState(''); // 챗봇이름의 id값
    const [rename, setRename] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // 챗봇이름 체크시 오류 메시지 설정
    const [okMessage, setOkMessage] = useState(''); // 챗봇이름 체크시 성공 메시지 설정
    const [isDuplicated, setIsDuplcated] = useState(false);
    const [decideChatbotName, setDecideChatbotName] = useState(false);
    const [isChatCopied, setIsChatCopied] = useState(false);
    const [isAdminCopied, setIsAdminCopied] = useState(false);
    const [botNameAlreadyRegistered, setBotNameAlreadyRegistered] = useState(false);

    useEffect(() => {
        const fetchChatbotName = async () => {
            // 챗봇 이름(+ 챗봇id) 받아와서 화면에 보여주는 함수
            try {
                const response = await axios.get('https://camel-personal-api.o2o.kr/user/chatbot-name/', authHeader);
                console.log('get chatbot name >>', response);
                setChatName(response.data.chatbot_name);
                setChatId(response.data.chatbot_id);
                localStorage.setItem('chatbot_id', response.data.chatbot_id);
                setRename(response.data.chatbot_name);
                setIsDuplcated(true);
                setDecideChatbotName(true);
                setBotNameAlreadyRegistered(true);
            } catch (error) {
                console.error('get chatbot name error >>', error);
            }
        }

        fetchChatbotName();
    }, [])

    const handleInputChange = (event) => {
        const { value } = event.target;
        const isValid = /^[가-힣A-Za-z0-9]{2,}$/.test(value);
    
        if (!isValid) {
            setErrorMessage('한글, 영어, 숫자만 공백없이 입력 가능하며, 최소 2글자 이상이어야 합니다.');
        } else {
            setErrorMessage('');
            setChatName(value);
        }
    };
    
    const checkNameDuplication = async () => {
        // 챗봇 이름 중복확인 함수
        if (!chatName.trim()) {
            alert('이름을 입력하지 않았거나 특수문자가 들어있습니다.');
            console.log('isDuplicated >>', isDuplicated)
            return;
        }
        
        try {
            const response = await axios.get(`https://camel-personal-api.o2o.kr/user/chatbot-name/?chatbot_name=${chatName}`, authHeader)

            console.log('chatbot name response >>', response)
            if (response.status === 200) {
                alert('사용 가능한 이름입니다.');
                setIsDuplcated(true);
            }
        } catch (error) {
            if (error.response) {
                const { status } = error.response;
                if (status === 404) {
                    alert('챗봇 이름이 등록되어 있지 않으니 저장해주세요');
                    setIsDuplcated(false);
                } else if (status === 409) {
                    alert('이미 사용 중인 이름입니다.');
                    setIsDuplcated(false);
                } else {
                    alert('인자가 없습니다.');
                    console.log('chatbot name status >>', status);
                    console.log('There is no "name" parameter');
                    setIsDuplcated(false);
                }
            } else {
                console.error('에러 발생 >>', error);
                alert('오류가 발생했습니다. 다시 시도해주세요.');
            }
        }
    };

    const confirmChatbotName = async () => {
        // 챗봇 이름 편집(수정)/저장 함수
        if (!chatName.trim()) {
            alert('이름을 입력하지 않았거나 특수문자가 들어있습니다.');
            return;
        } 
        if (!isDuplicated) {
            alert('챗봇 이름 중복 확인을 해주세요');
            return;
        }
    
        const userConfirmed = window.confirm(`${chatName}을(를) 정말 챗봇 이름으로 하시겠습니까?`);

        if (isDuplicated && userConfirmed) {
            const chatNameInfo = {
                id: chatNameId,
                name: chatName
            }
            
            try {
                const response = await axios.patch('https://camel-personal-api.o2o.kr/user/chatbot-name/', chatNameInfo, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
                    },
                }).then((res) => {
                    console.log('data status >>', res.status);

                    if (res.status === 200) {
                        console.log('200 res. >>', res);
                        setDecideChatbotName(true);
                        alert('챗봇 이름이 성공적으로 저장되었습니다!');
                        setChatName(chatName);
                        setRename(chatName);
                    }
                });
            } catch (error) {
                if (error.response) {
                    console.error('API 호출 중 오류 발생 >>', error.response);
                    if (error.response.status === 404) {
                        console.log('404 error >>', error.response);
                        alert('챗봇 이름이 등록되어 있지 않으니 저장해주세요');
                    } else if (error.response.status === 409) {
                        console.log('409 error >>', error.response);
                        alert('이미 사용중인 이름입니다.');
                    } else {
                        console.log('error for editing chatbot name', error.response.statusText);
                        alert('다른 이름으로 다시 시도해주세요.');
                    }
                } else {
                    console.error('API 호출 중 오류 발생 >>', error);
                    alert('서버 에러. 나중에 다시 시도해주세요.');
                }
            }
        }
    };

    // TODO | 이름 중복확인 + DB저장 하나로 합치는중 (검토필요)
    const handleChatbotName = async () => {
        // 챗봇 이름 중복 확인 및 저장 함수
        checkNameDuplication();
        confirmChatbotName();
    };


    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [pdfFileNames, setPdfFileNames] = useState([]);
    const [filesEmbeddingStatus, setFilesEmbeddingStatus] = useState([]);
    const [lastClicked, setLastClicked] = useState(0);

    useEffect(() => {
        axios.get(`https://camel-personal-api.o2o.kr/pdf/`, authHeader)
            .then(response => {
                console.log('get pdf files response >>', response)
                if (response.data && response.data.result.length > 0) {
                    const filesOnServer = response.data.result.map(file => file.file_name);
                    const statuses = response.data.result.map(file => file.status);
                    console.log('files on server >>', filesOnServer)
                    console.log('embedding statuses >>', statuses)

                    setPdfFileNames(filesOnServer);
                    setFilesEmbeddingStatus(statuses);

                    setUploadSuccess(true);
                    setPdfAlreadyRegistered(true);
                } else {
                    setPdfFileNames([]);
                    setFilesEmbeddingStatus([]);
                }
            })
            .catch(error => {
                console.error('Error from fetching PDF file names & embedding status >>', error);
                setPdfFileNames([]);
                setFilesEmbeddingStatus([]);
            });
    }, []);

    const [pdfAlreadyRegistered, setPdfAlreadyRegistered] = useState(
        !!pdfFileNames && pdfFileNames.length > 0
    );

    // 파일 업로드 상태 관리
    const handleUploadSuccess = (isSuccess, newFileNames) => {
        if (isSuccess) {
            setPdfFileNames(prevNames => [...prevNames, ...newFileNames]);
            setFilesEmbeddingStatus(prevStatuses => [
                ...prevStatuses,
                ...newFileNames.map(() => 'STARTED') // 새 파일에 대한 상태를 'STARTED'로 설정
            ]);
            console.log('emb. status update >>', filesEmbeddingStatus)
            localStorage.setItem('pdf_file_name', JSON.stringify([...pdfFileNames, ...newFileNames]));
            setUploadSuccess(true);
            setPdfAlreadyRegistered(true);
        }
    };

    // 서버에 파일 삭제 컨트롤러
    const handleRemovePdfFile = async (index) => {
        const fileNameToDelete = pdfFileNames[index];
        
        const isConfirmed = window.confirm(
            `다음 파일을 정말로 삭제하시겠습니까?\n'${fileNameToDelete}'`
        );

        if (!isConfirmed) {
            return; // 삭제 확인 중 취소를 눌렀을 때
        }

        // 서버에 파일 삭제 요청
        try {
            const response = await axios.delete(`https://camel-personal-api.o2o.kr/pdf/file/${fileNameToDelete}/`, authHeader); // 서버에 저장된 pdf 파일 삭제 API 적용
            if (response.status === 200) {
                // console.log('File is successfully deleted >>', fileNameToDelete);
                
                // 로컬 상태에서도 파일 및 해당 파일 상태 삭제
                const updatedFiles = [...pdfFileNames];
                const updatedStatuses = [...filesEmbeddingStatus];
                updatedFiles.splice(index, 1);
                updatedStatuses.splice(index, 1);
                setPdfFileNames(updatedFiles);
                setFilesEmbeddingStatus(updatedStatuses);
                localStorage.setItem('pdf_file_name', JSON.stringify(updatedFiles));
    
                alert('파일이 성공적으로 삭제되었습니다.');
            } else {
                throw new Error('Failed to delete the file from the server.');
            }
        } catch (error) {
            console.error('Error deleting file >>', error);
            alert('파일 삭제 중 오류가 발생했습니다. 나중에 다시 시도하세요.');
        }
    };

    // pdf 임베딩 상태를 '수동으로' 체크하는 함수
    const checkPdfStatus = () => {
        const now = new Date().getTime();
        const timeSinceLastClick = now - lastClicked;

        if (timeSinceLastClick >= 5000) { // 5초 제한
            setLastClicked(now);
            axios.get(`https://camel-personal-api.o2o.kr/pdf/`, authHeader)
                .then(response => {
                    if (response.data && response.data.result.length > 0) {
                        const fileNames = response.data.result.map(file => file.file_name);
                        const statuses = response.data.result.map(file => file.status);
                        setPdfFileNames(fileNames);
                        setFilesEmbeddingStatus(statuses);
                    } else {
                        setPdfFileNames([]);
                        setFilesEmbeddingStatus([]);
                    }
                })
                .catch(error => {
                    console.error('Error fetching PDF statuses >>', error);
                    setPdfFileNames([]);
                    setFilesEmbeddingStatus([]);
                });
        } else {
            const remainingTime = 5 - Math.floor(timeSinceLastClick / 1000);
            alert(`${remainingTime}초 후 확인 가능`);
        }
    };

    useEffect(() => {
        // 로그 출력용 시간 계산
        const now = new Date();
        const hours = ('0' + now.getHours()).slice(-2);
        const minutes = ('0' + now.getMinutes()).slice(-2);
        const seconds = ('0' + now.getSeconds()).slice(-2);
        const timeString = hours + ':' + minutes  + ':' + seconds;
    
        const autoCheckStatus = async () => {
            await axios.get('https://camel-personal-api.o2o.kr/pdf/', authHeader)
                .then((response) => {
                    const fileNames = response.data.result.map(file => file.file_name);
                    const newStatuses = response.data.result.map(file => file.status);
                    console.log('Fetched statuses >>', newStatuses, timeString);
                    setPdfFileNames(fileNames);
                    setFilesEmbeddingStatus(newStatuses);
                })
                .catch((error) => {
                    console.error('Error fetching data >>', error);
                });
        };
        
        if (filesEmbeddingStatus.includes('STARTED')) {
            const intervalId = setInterval(() => {
                autoCheckStatus();
            }, 5000);

            return () => clearInterval(intervalId);
        }
    
    }, [filesEmbeddingStatus]);
    

    // 챗봇, 관리자 페이지 URL 복사 기능 컨트롤러
    const handleCopyClipBoard = async (text, type) => {
        try {
            await navigator.clipboard.writeText(text);
            if (type === 'chat') {
                setIsChatCopied(true);
                setTimeout(() => setIsChatCopied(false), 3000);
            } else if (type === 'admin') {
                setIsAdminCopied(true);
                setTimeout(() => setIsAdminCopied(false), 3000);
            }
        } catch (err) {
            console.log('copy error >>', err);
            if (type === 'chat') {
                setIsChatCopied(false);
            } else if (type === 'admin') {
                setIsAdminCopied(false);
            }
        }
    };

    const [showPopup, setShowPopup] = useState(false); // 이미지 팝업 표시 여부
    const [currentImageUrl, setCurrentImageUrl] = useState('');  // 현재 팝업에 표시할 이미지 URL

    // 이미지 클릭 시 팝업 표시/숨김 토글
    const handleImageClick = (imageUrl) => {
        setCurrentImageUrl(imageUrl);  // 클릭된 이미지 URL 설정
        setShowPopup(true);            // 팝업 표시
    };

    return (
        <div className='wrap'>
            {/* <div className='home-container'>
                <p className='section-title'>사용 매뉴얼</p>
                <p>사용 매뉴얼은 준비중입니다.</p>
                {/* <button onClick={() => window.open(`/assets/pdf/camel_kepco_manual.pdf`)} // 매뉴얼 수정 후 파일 변경할 것
                    style={{
                        width: '90%',
                        height: '5vh',
                        cursor: 'pointer',
                        borderRadius: '10px',
                        
                    }}>
                    사용 매뉴얼 보기
                </button>
            </div> */}
            
            <div className='home-container'>
                <div className='name-area'>
                    <div className='title-info-area'>
                        <p className='section-title'>챗봇 이름 입력</p>
                        <img src={"/img/info_icon.png"} 
                            alt="more-info" 
                            onClick={() => handleImageClick('/img/manual_1.svg')} />
                    </div>
                    <p style={{marginBottom: '10px'}}>현재 챗봇 이름 : {rename}</p>
                    <input 
                        className='userId-input' 
                        id='userId-input' 
                        type='text' 
                        onBlur={handleInputChange}
                        maxLength="10" 
                        placeholder='특수문자를 제외한 2~10 글자'
                    />
                    
                    {!botNameAlreadyRegistered && (
                        <>
                            {/* <button 
                                onClick={checkNameDuplication} 
                                style={{height:'40px', borderRadius:'10px', marginRight:'10px', cursor:'pointer'}}
                            >
                                중복확인
                            </button> */}
                            <button 
                                onClick={confirmChatbotName} 
                                style={{height:'40px', borderRadius:'10px', marginRight:'10px', cursor:'pointer'}}
                            >
                                결정
                            </button>
                        </>
                    )}
                    
                    {botNameAlreadyRegistered && (
                        <button 
                            onClick={confirmChatbotName}
                            style={{height:'40px', borderRadius:'10px', backgroundColor:'#4a4ef5', color:'white', cursor:'pointer'}}
                        >
                            이름바꾸기
                        </button>
                    )}
                    {okMessage && (
                        <div style={{ color: 'green', padding: '10px' }}>{okMessage}</div>
                    )}
                    {errorMessage && (
                        <div style={{ color: 'red', padding: '10px' }}>{errorMessage}</div>
                    )}
                </div>
            </div>
            
            {/* 가이드 아이콘 클릭 시 뜨는 팝업 이미지 */}
            {showPopup && (
                <div className="image-popup-overlay" onClick={() => setShowPopup(false)} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="image-popup-content" style={{ width: '430px', position: 'relative', padding: '20px', background: 'white', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <img 
                            src={currentImageUrl} 
                            alt="Popup Image" 
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                        <p>닫기</p>
                    </div>
                </div>
            )}

            {/* {isDuplicated && decideChatbotName && ( */}
            {decideChatbotName && (
                <div className='home-container'>
                    <div className='title-info-area'>
                        <p className='section-title'>PDF List</p>
                        <img src={"/img/info_icon.png"} 
                            alt="more-info" 
                            onClick={() => handleImageClick('/img/manual_2.svg')} />
                    </div>
                    <p>동일한 이름의 PDF 파일은 업로드 할 수 없습니다.</p>
                    <p>기존 파일 삭제 후 업로드 해주시기 바랍니다.</p>
                    {pdfAlreadyRegistered && (
                        <div className='data-already-uploaded' style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            {/* <p>{userName}님은 PDF 파일을 업로드한 기록이 있습니다.</p> */}
                            <div className='uploaded-data-list' style={{
                                fontWeight: 600, 
                                // maxWidth: '90%',
                                width: '90%',
                                height: '100%',
                                backgroundColor: '#d6b082c2',
                                display: 'block',
                                padding: '10px',
                                marginTop : '5px',
                                marginBottom: '10px',
                                borderRadius: '10px'
                            }}>
                                <p>업로드한 파일 목록</p>
                                <ul style={{ paddingLeft:0 }}>
                                    {pdfFileNames.map((fileName, index) => (
                                        <li key={index} style={{
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "space-between", 
                                            marginBottom: '5px',
                                            background: 'white',
                                            margin: '5px',
                                            padding: '3px',
                                            textAlign: 'left',
                                            borderRadius: '5px'
                                        }}>
                                            <span style={{
                                                flex: "1 1 auto",
                                                overflow: 'hidden', 
                                                textOverflow: 'ellipsis', 
                                                whiteSpace: 'nowrap', 
                                                paddingLeft: '5px'
                                            }}>
                                                {fileName}
                                            </span>
                                            <img src={
                                                filesEmbeddingStatus[index] === 'SUCCESS' ? '/img/emb_success.png' :
                                                filesEmbeddingStatus[index] === 'STARTED' ? '/img/emb_loading.gif' :
                                                filesEmbeddingStatus[index] === 'FAILED' ? '/img/emb_failed.png' : '/img/emb_loading.gif'
                                            } alt="Status Icon" style={{ width: '20px', height: '20px' }} />
                                            
                                            {filesEmbeddingStatus[index] === 'STARTED' ? (
                                                <button style={{
                                                    flex: "0 0 50px",
                                                    margin: '0px 5px 0px 10px',
                                                    backgroundColor: 'transparent',
                                                    color: 'black',
                                                    borderRadius: '5px',
                                                    cursor: 'not-allowed',
                                                    fontWeight: 600
                                                }} disabled>
                                                    <span className="wave-text">학습중</span>
                                                </button>
                                            ) : (
                                                <button style={{
                                                    flex: "0 0 50px",
                                                    margin: '0 5px 0 10px',
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer'
                                                }} onClick={() => handleRemovePdfFile(index)}>
                                                    삭  제
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                {/* 수동 pdf 임베딩 상태 체크 */}
                                {/* <label className='pdf-process-check' style={{ cursor: 'pointer' }}>
                                    <p style={{ marginTop: '15px' }}>PDF 학습 진행상태 확인</p>
                                    <img src='/img/refresh.png' alt='embedding-refresh' style={{ width: '50px' }} />
                                    <button onClick={checkPdfStatus} style={{ display: 'none' }} />
                                </label> */}
                            </div>
                        </div>
                    )}
                    
                    <div className='home-container'>
                        <p className='section-title'>PDF 업로드</p>
                        <div className='upload-area'>
                            <FileUploadComponent onUploadSuccess={handleUploadSuccess} />
                        </div>
                    </div>
                </div>
            )}
            
            {chatName && (
                <div className='home-container'>
                    <div className='title-info-area'>
                        <p className='section-title'>자주 찾는 질문 List 추가하기</p>
                        <img src={"/img/info_icon.png"} alt="more-info" onClick={() => handleImageClick('/img/manual_3.svg')} />
                    </div>
                    <SuggestQuestion />
                </div>
            )}

            {chatName && uploadSuccess && (
                <>
                    <div className='home-container'>
                        <p className='section-title'>챗봇</p>
                        <button className='link-btn' onClick={() => window.open(`https://camel-personal.o2o.kr/chatbot/${chatName}`)}>{userName}님의 '{rename}' 챗봇 바로가기</button>
                        <button className='copy-btn' onClick={() => handleCopyClipBoard(`https://camel-personal.o2o.kr/chatbot/${chatName}`, 'chat')}>복사</button>
                        {isChatCopied && <p style={{color:'green'}}>복사되었습니다.</p>}
                    </div>
                    
                    <div className='home-container'>
                        <p className='section-title'>챗봇 관리 페이지</p>
                        <button className='link-btn'  onClick={() => window.open(`https://camel-personal.o2o.kr/admin/${chatName}`)}>{userName}님의 챗봇 관리 페이지 바로가기</button>
                        <button className='copy-btn' onClick={() => handleCopyClipBoard(`https://camel-personal.o2o.kr/admin/${chatName}`, 'admin')}>복사</button>
                        {isAdminCopied && <p style={{color:'green'}}>복사되었습니다.</p>}
                    </div>
                </>
            )}
        </div>
    );
}

export default Home;