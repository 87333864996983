// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.go-to-home {
    display: flex;
    justify-content: center;
}

.go-to-home button {
    margin-top: 20px;
    width: 30vh;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    font-size: larger;
    background-color: #ebd5ba;
}`, "",{"version":3,"sources":["webpack://./src/css/Guide.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".go-to-home {\n    display: flex;\n    justify-content: center;\n}\n\n.go-to-home button {\n    margin-top: 20px;\n    width: 30vh;\n    height: 50px;\n    border-radius: 10px;\n    cursor: pointer;\n    font-size: larger;\n    background-color: #ebd5ba;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
